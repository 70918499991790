function intersperse(arr, value) {
    return arr.reduce((result, item, index) => {
        if (index > 0) {
            result.push(value);
        }
        result.push(item);
        return result;
    }, []);
}
function randomItem(array) {
    const index = Math.floor(Math.random() * array.length);
    return array[index];
}
function isSuperset(superset, subset) {
    return superset === subset || subset.every(item => superset.includes(item));
}
export const Array = {
    intersperse,
    randomItem,
    isSuperset,
};
