/** Custom class to use when sub-classing Error. */
export class CustomError extends Error {
    constructor(message) {
        // "Error" breaks the prototype chain here.
        super(message);
        // Restore it.
        const actualPrototype = new.target.prototype;
        Object.setPrototypeOf(this, actualPrototype);
    }
}
export class ErrorWithData extends CustomError {
    data;
    constructor(message, data) {
        super(message);
        this.data = data;
    }
}
export function never() {
    throw new Error("This should never happen.");
}
