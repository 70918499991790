export function noop() {
    // Do nothing.
}
export function unreachable(_x) {
    throw new Error("Unreachable: this should not happen.");
}
export function isDefined(value) {
    return typeof value !== "undefined";
}
export function isDefinedOr(value, backup) {
    return isDefined(value) ? value : backup;
}
export function isPromise(value) {
    return Boolean(value &&
        typeof value === "object" &&
        "then" in value &&
        typeof value.then === "function" &&
        "catch" in value &&
        typeof value.catch === "function");
}
