import { createContext, useContext } from "react";
export function buildStoreContext() {
    const StoreContext = createContext(undefined);
    function useStoreContext() {
        const storeContext = useContext(StoreContext);
        if (!storeContext) {
            throw new Error("Store provider is required.");
        }
        return storeContext;
    }
    return {
        StoreContext,
        useStoreContext,
    };
}
