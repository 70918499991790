import { JSONPath } from "jsonpath-plus";
import { normalizePath } from "./pathHelpers.js";
//
// Model.
//
export var QuerySortProperty;
(function (QuerySortProperty) {
    QuerySortProperty["CREATED_AT"] = "createdAt";
    QuerySortProperty["RECEIVED_AT"] = "receivedAt";
    QuerySortProperty["VERSION_CREATED_AT"] = "version.createdAt";
    QuerySortProperty["VERSION_RECEIVED_AT"] = "version.receivedAt";
})(QuerySortProperty || (QuerySortProperty = {}));
export var QuerySortDirection;
(function (QuerySortDirection) {
    QuerySortDirection["ASCENDING"] = "ascending";
    QuerySortDirection["DESCENDING"] = "descending";
})(QuerySortDirection || (QuerySortDirection = {}));
//
// I/O.
//
const querySortDirectionMap = {
    [QuerySortDirection.ASCENDING]: "asc",
    [QuerySortDirection.DESCENDING]: "desc",
};
function querySortToString(querySort) {
    const property = normalizePath(querySort[0]);
    const direction = querySortDirectionMap[querySort[1]];
    return `${property}+${direction}`;
}
function querySortToDirection(querySort) {
    return querySort[1];
}
function findDateInRecord(record, querySort) {
    switch (querySort[0]) {
        case QuerySortProperty.CREATED_AT:
            return record.createdAt;
        case QuerySortProperty.RECEIVED_AT:
            return record.receivedAt;
        case QuerySortProperty.VERSION_CREATED_AT:
            return record.version?.createdAt;
        case QuerySortProperty.VERSION_RECEIVED_AT:
            return record.version?.receivedAt;
        default: {
            const recordValues = JSONPath({
                path: querySort[0],
                json: record,
            });
            return recordValues[0];
        }
    }
}
export const QuerySort = {
    toString: querySortToString,
    toDirection: querySortToDirection,
    findDateInRecord,
};
