/* eslint-disable @typescript-eslint/no-unused-vars */
import * as IO from "../../helpers/io.js";
function buildRecordType(entity, recordId, versionHash) {
    const RType = IO.object({
        entity: IO.literal(entity),
        recordId: IO.literal(recordId),
        versionHash: IO.string,
    });
    function typeCreator(_content) {
        return {
            entity,
            recordId,
            versionHash,
        };
    }
    return [RType, typeCreator];
}
export const RAnyRecordType = IO.object({
    entity: IO.string,
    recordId: IO.string,
    versionHash: IO.string,
});
function buildRecordTypeFull(entity, recordId, versionHash, _content) {
    const type = {
        entity,
        recordId,
        versionHash,
    };
    const RType = IO.object({
        entity: IO.literal(entity),
        recordId: IO.literal(recordId),
        versionHash: IO.string,
    });
    return [type, RType];
}
function typeToKey(type) {
    return `${type.entity}+${type.recordId}`;
}
export const RecordType = {
    new: buildRecordType,
    full: buildRecordTypeFull,
    toKey: typeToKey,
};
