import * as IO from "../../helpers/io.js";
//
// Runtime model.
//
export class REntityLinkClass extends IO.Type {
    constructor() {
        const model = IO.dualObject({
            entity: IO.string,
        }, {
            originalEntity: IO.string,
            versionCreatedAt: IO.isoDate,
        });
        super("EntityLink", model.is, model.validate, model.encode);
    }
}
const REntityLink = new REntityLinkClass();
//
// I/O.
//
function buildEntityLink(entity) {
    return { entity };
}
export const EntityLink = {
    io: () => REntityLink,
    new: buildEntityLink,
};
