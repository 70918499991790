const linkHeaderRegexp = /<([^>]+)>;\srel="([^"]+)"/g;
export function findLink(headers, rel) {
    const linkHeader = headers.get("Link");
    if (!linkHeader) {
        return undefined;
    }
    const firstLink = [...linkHeader.matchAll(linkHeaderRegexp)]
        .map(g => [g[1], g[2]])
        .filter(([, r]) => r === rel)
        .map(([link]) => link)
        .at(0);
    return firstLink;
}
