import { BlobLink } from "../model/links/blobLink.js";
import { AppRecord } from "../model/recordTypes/appRecord.js";
import { buildServerCredentialsRecord } from "../model/recordTypes/serverCredentialsRecord.js";
import { Client } from "./client.js";
const allowedIconTypes = ["image/jpeg", "image/png"];
async function register(entity, appContent, { icon, signal } = {}) {
    // Perform discovery.
    const client = Client.ofEntity(entity);
    const entityRecord = await client.getEntityRecord(signal);
    // Upload the app icon, if any.
    const iconLink = await (async () => {
        if (!icon) {
            return undefined;
        }
        if (!allowedIconTypes.includes(icon.type)) {
            throw new Error("Unsupported icon mime type.");
        }
        const blob = await client.uploadBlob(icon, signal);
        return BlobLink.new(blob, icon.type, "icon");
    })();
    // Add the icon (if any) to the app record content.
    const fullAppContent = {
        ...appContent,
        icon: iconLink,
    };
    // Create the app record.
    const appRecord = AppRecord.new(entityRecord.author.entity, fullAppContent);
    const credentialsRecord = buildServerCredentialsRecord(appRecord);
    const authenticatedClient = Client.authenticated({
        entityRecord,
        appRecord,
        credentialsRecord,
        serverPublicKey: undefined,
        authorizationId: undefined,
    });
    const [serverPublicKey, serverAppRecord] = await authenticatedClient.postAppRecord(appRecord, credentialsRecord, signal);
    // Resolve the authentication flow URL.
    const flowUrl = await client.expandUrlTemplate("auth", { record_id: appRecord.id }, signal);
    const state = {
        authorizationId: undefined,
        entityRecord,
        appRecord: serverAppRecord,
        credentialsRecord,
        serverPublicKey,
    };
    return {
        flowUrl,
        state,
    };
}
function complete(state, authorizationId) {
    return {
        ...state,
        authorizationId,
    };
}
export const Authentication = {
    register,
    complete,
};
