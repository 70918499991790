import { EntityLink, EntityRecord, Q, Query, RecordSource, StandingDecision, StandingRecord, } from "@baqhub/sdk";
function pickRecord(local, root) {
    if (!local) {
        return root;
    }
    if (!root) {
        return local;
    }
    if (local.createdAt > root.createdAt) {
        return local;
    }
    return root;
}
export function recordByVersion(versions) {
    return (version) => {
        return versions[version];
    };
}
export function recordByKey(entity, proxyEntity) {
    return (getState) => (key) => {
        const state = getState();
        const record = (() => {
            const localRecord = state[proxyEntity]?.dictionary[key];
            if (proxyEntity === entity) {
                return localRecord;
            }
            return pickRecord(localRecord, state[entity]?.dictionary[key]);
        })();
        if (!record || "noContent" in record) {
            throw new Error("This record does not exist: " + key);
        }
        return record;
    };
}
export function findRecordByKey(entity, proxyEntity) {
    return (getState) => (key) => {
        const record = (() => {
            const state = getState();
            const localRecord = state[proxyEntity]?.dictionary[key];
            if (proxyEntity === entity) {
                return localRecord;
            }
            return pickRecord(localRecord, state[entity]?.dictionary[key]);
        })();
        if ("noContent" in record) {
            throw new Error("This record does not exist: " + key);
        }
        return record;
    };
}
export function findRecordByQuery(entity, proxyEntity) {
    return (getState) => (query) => {
        const state = getState();
        const findRecord = (findEntity) => {
            const records = Query.filter(state[findEntity]?.list || [], {
                ...query,
                pageSize: 1,
            });
            if (records.length > 1) {
                throw new Error("Multiple records found.");
            }
            return records[0];
        };
        if (proxyEntity === entity) {
            return findRecord(proxyEntity);
        }
        return pickRecord(findRecord(proxyEntity), findRecord(entity));
    };
}
export function findEntityRecord(entity, proxyEntity) {
    const findRecord = findRecordByQuery(entity, proxyEntity);
    return (getState) => (targetEntity) => {
        return findRecord(getState)({
            sources: [
                RecordSource.SELF,
                RecordSource.NOTIFICATION,
                RecordSource.SUBSCRIPTION,
                RecordSource.RESOLUTION,
            ],
            filter: Q.and(Q.author(targetEntity), Q.type(EntityRecord)),
        });
    };
}
export function findStandingRecord(entity) {
    const findRecord = findRecordByQuery(entity, entity);
    return (getState) => (publisherEntity) => {
        return findRecord(getState)({
            sources: [RecordSource.SELF],
            filter: Q.and(Q.author(entity), Q.type(StandingRecord), Q.entity("content.publisher", publisherEntity)),
        });
    };
}
export function findStandingDecision(entity) {
    const findRecord = findStandingRecord(entity);
    return (getState) => (publisherEntity) => {
        const standingRecord = findRecord(getState)(publisherEntity);
        if (!standingRecord) {
            return StandingDecision.UNDECIDED;
        }
        return standingRecord.content.decision;
    };
}
export function updateStandingDecision(entity) {
    const findRecord = findStandingRecord(entity);
    return (getState, updateRecord) => (publisherEntity, decision) => {
        const standingRecord = findRecord(getState)(publisherEntity);
        const newContent = {
            publisher: EntityLink.new(publisherEntity),
            decision,
        };
        const newStandingRecord = standingRecord
            ? StandingRecord.update(entity, standingRecord, newContent)
            : StandingRecord.new(entity, newContent);
        updateRecord([newStandingRecord]);
    };
}
