import { base64Bytes, decode, utf8Bytes } from "../../helpers/io.js";
import { sign } from "../../helpers/signature.js";
import { Str } from "../../helpers/string.js";
import { CredentialsAlgorithm, RCredentialsAlgorithm, } from "../core/credentialsAlgorithm.js";
import { HttpMethod } from "../core/httpMethod.js";
//
// I/O.
//
function signatureToQuery(signature) {
    const values = [
        signature.id,
        signature.timestamp,
        base64Bytes.encode(signature.signature),
    ];
    return Str.toUrlBase64(values.join("\\"));
}
//
// Sign.
//
export function signatureForRequest(appRecordId, privateKey, input, timestamp) {
    const algorithm = CredentialsAlgorithm.ED_25519;
    const signatureString = [
        "baq.url",
        RCredentialsAlgorithm.encode(algorithm),
        timestamp,
        "",
        input.authorizationId || "",
        HttpMethod.GET,
        input.pathAndQuery,
        input.host,
        input.port,
        "",
    ].join("\n");
    const signatureBytes = sign(privateKey, decode(utf8Bytes, signatureString));
    return {
        id: appRecordId,
        timestamp,
        algorithm,
        signature: signatureBytes,
    };
}
export const HttpBearerSignature = {
    request: signatureForRequest,
    toQuery: signatureToQuery,
};
