import { isLeft } from "fp-ts/lib/Either.js";
import { Canonicalization } from "./canonicalization.js";
import * as IO from "./io.js";
import { Str } from "./string.js";
import { isDefined } from "./type.js";
function countUniqueItems(array) {
    return new Set(array.map(Canonicalization.canonicalize)).size;
}
function schemaArray(itemsSchema, options = {}) {
    const { minItems, maxItems, distinctItems } = options;
    const baseType = IO.readonlyArray(itemsSchema);
    function validateValue(value) {
        if (isDefined(minItems) && value.length < minItems) {
            return false;
        }
        if (isDefined(maxItems) && value.length > maxItems) {
            return false;
        }
        if (distinctItems && countUniqueItems(value) !== value.length) {
            return false;
        }
        return true;
    }
    function isSchemaArray(value) {
        if (!baseType.is(value)) {
            return false;
        }
        return validateValue(value);
    }
    return new IO.Type("SchemaArray", isSchemaArray, (value, context) => {
        const result = baseType.validate(value, context);
        if (isLeft(result)) {
            return IO.failure(value, context);
        }
        if (!validateValue(result.right)) {
            return IO.failure(value, context, "Not a valid SchemaArray.");
        }
        return IO.success(result.right);
    }, value => baseType.encode(value));
}
//
// Map.
//
function schemaMap(valuesSchema) {
    return IO.record(IO.string, valuesSchema);
}
//
// Boolean.
//
function schemaBoolean() {
    return IO.boolean;
}
function schemaString(options = {}) {
    const { minLength, maxLength } = options;
    const baseType = IO.string;
    function validateValue(value) {
        // Normalization.
        if (value.normalize() !== value) {
            return false;
        }
        if (!isDefined(minLength) && !isDefined(maxLength)) {
            return true;
        }
        const length = Str.unicodeLength(value);
        // MinLength.
        if (isDefined(minLength) && length < minLength) {
            return false;
        }
        // MaxLength.
        if (isDefined(maxLength) && length > maxLength) {
            return false;
        }
        return true;
    }
    function isSchemaString(value) {
        if (!baseType.is(value)) {
            return false;
        }
        return validateValue(value);
    }
    return new IO.Type("SchemaString", isSchemaString, (value, context) => {
        const result = baseType.validate(value, context);
        if (isLeft(result)) {
            return IO.failure(value, context);
        }
        if (!validateValue(result.right)) {
            return IO.failure(value, context, "Not a valid SchemaString.");
        }
        return IO.success(result.right);
    }, value => baseType.encode(value));
}
function schemaInt(options = {}) {
    const { min, max } = options;
    const baseType = IO.Int;
    function validateValue(value) {
        if (isDefined(min) && value < min) {
            return false;
        }
        if (isDefined(max) && value > max) {
            return false;
        }
        return true;
    }
    function isSchemaInt(value) {
        if (!baseType.is(value)) {
            return false;
        }
        return validateValue(value);
    }
    return new IO.Type("SchemaInt", isSchemaInt, (value, context) => {
        const result = baseType.validate(value, context);
        if (isLeft(result)) {
            return IO.failure(value, context);
        }
        if (!validateValue(result.right)) {
            return IO.failure(value, context, "Not a valid SchemaInt.");
        }
        return IO.success(result.right);
    }, value => value);
}
function schemaNumber(options = {}) {
    const { min, max } = options;
    const baseType = IO.number;
    function validateValue(value) {
        if (isDefined(min) && value < min) {
            return false;
        }
        if (isDefined(max) && value > max) {
            return false;
        }
        return true;
    }
    function isSchemaNumber(value) {
        if (!baseType.is(value)) {
            return false;
        }
        return validateValue(value);
    }
    return new IO.Type("SchemaNumber", isSchemaNumber, (value, context) => {
        const result = baseType.validate(value, context);
        if (isLeft(result)) {
            return IO.failure(value, context);
        }
        if (!validateValue(result.right)) {
            return IO.failure(value, context, "Not a valid SchemaNumber.");
        }
        return IO.success(result.right);
    }, value => value);
}
export const SchemaIO = {
    object: IO.dualObject,
    array: schemaArray,
    map: schemaMap,
    boolean: schemaBoolean,
    string: schemaString,
    int: schemaInt,
    number: schemaNumber,
};
