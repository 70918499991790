import reduce from "lodash/reduce.js";
import { tryDecode } from "../../helpers/io.js";
import { RHttpSignatureHeader, } from "./httpSignatureHeader.js";
//
// Helpers.
//
function signatureInputFromRequest(method, url, headers, authorizationId) {
    const headerValues = reduce(headers, (result, value, header) => {
        const signatureHeader = tryDecode(RHttpSignatureHeader, header);
        if (!signatureHeader) {
            return result;
        }
        result.set(signatureHeader, value);
        return result;
    }, new Map());
    const urlObj = new URL(url);
    return {
        authorizationId,
        method,
        pathAndQuery: urlObj.pathname + urlObj.search,
        host: urlObj.hostname,
        port: Number(urlObj.port) || 443,
        headerValues,
    };
}
export const HttpSignatureInput = {
    new: signatureInputFromRequest,
};
