/* eslint-disable @typescript-eslint/no-unused-vars */
import { unreachable } from "../../helpers/type.js";
//
// Model.
//
export var QueryLinkValueType;
(function (QueryLinkValueType) {
    QueryLinkValueType["TAG"] = "tag";
    QueryLinkValueType["ENTITY"] = "entity";
    QueryLinkValueType["RECORD"] = "record";
    QueryLinkValueType["VERSION"] = "version";
})(QueryLinkValueType || (QueryLinkValueType = {}));
//
// I/O.
//
function queryValueTag(tag) {
    return [QueryLinkValueType.TAG, tag];
}
function queryValueEntity(entity) {
    return [QueryLinkValueType.ENTITY, { entity }];
}
function queryValueRecord(recordLink) {
    return [QueryLinkValueType.RECORD, recordLink, undefined];
}
function queryValueVersion(versionLink) {
    return [QueryLinkValueType.VERSION, versionLink];
}
function queryLinkValueToString(queryLinkValue) {
    switch (queryLinkValue[0]) {
        case QueryLinkValueType.TAG:
            return JSON.stringify(queryLinkValue[1]);
        case QueryLinkValueType.ENTITY:
            return queryLinkValue[1].entity;
        case QueryLinkValueType.RECORD: {
            const { entity, recordId } = queryLinkValue[1];
            return `${entity}+${recordId}`;
        }
        case QueryLinkValueType.VERSION: {
            const { entity, recordId, versionHash } = queryLinkValue[1];
            return `${entity}+${recordId}+${versionHash}`;
        }
        default:
            unreachable(queryLinkValue[0]);
    }
}
function queryLinkValueIs(value, obj) {
    switch (value[0]) {
        case QueryLinkValueType.TAG:
            return value[1] === obj;
        case QueryLinkValueType.ENTITY: {
            return obj?.entity === value[1].entity;
        }
        case QueryLinkValueType.RECORD: {
            return (obj?.entity === value[1].entity && obj?.recordId === value[1].recordId);
        }
        case QueryLinkValueType.VERSION: {
            return (obj?.entity === value[1].entity &&
                obj?.recordId === value[1].recordId &&
                obj?.versionHash === value[1].versionHash);
        }
        default:
            unreachable(value[0]);
    }
}
function queryLinkValuesMatch(value1, value2) {
    switch (value1[0]) {
        case QueryLinkValueType.TAG:
            return value2[0] === QueryLinkValueType.TAG && value1[1] === value2[1];
        case QueryLinkValueType.ENTITY:
            return (value2[0] === QueryLinkValueType.ENTITY &&
                value1[1].entity === value2[1].entity);
        case QueryLinkValueType.RECORD:
            return (value2[0] === QueryLinkValueType.RECORD &&
                value1[1].entity === value2[1].entity &&
                value1[1].recordId === value2[1].recordId);
        case QueryLinkValueType.VERSION:
            return (value2[0] === QueryLinkValueType.VERSION &&
                value1[1].entity === value2[1].entity &&
                value1[1].recordId === value2[1].recordId &&
                value1[1].versionHash === value2[1].versionHash);
        default:
            unreachable(value1[0]);
    }
}
export const QueryLinkValue = {
    tag: queryValueTag,
    entity: queryValueEntity,
    record: queryValueRecord,
    version: queryValueVersion,
    is: queryLinkValueIs,
    match: queryLinkValuesMatch,
    toString: queryLinkValueToString,
};
