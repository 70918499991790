import * as IO from "../../helpers/io.js";
import { AppRecord } from "../recordTypes/appRecord.js";
import { EntityRecord } from "../recordTypes/entityRecord.js";
import { ServerCredentialsRecord } from "../recordTypes/serverCredentialsRecord.js";
//
// Model.
//
const RAuthenticationStateRaw = IO.object({
    entityRecord: EntityRecord,
    appRecord: AppRecord,
    credentialsRecord: ServerCredentialsRecord,
    serverPublicKey: IO.union([IO.undefined, IO.base64Bytes]),
    authorizationId: IO.union([IO.undefined, IO.string]),
});
export const RAuthenticationState = IO.clean(RAuthenticationStateRaw);
//
// I/O.
//
export const AuthenticationState = {
    decode: (value) => {
        return IO.decode(RAuthenticationState, value);
    },
    decodeJSON: (value) => {
        return AuthenticationState.decode(JSON.parse(value));
    },
    encode: (state) => {
        return IO.encode(RAuthenticationState, state);
    },
    encodeJSON: (state) => {
        return JSON.stringify(AuthenticationState.encode(state));
    },
};
