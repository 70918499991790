import { unreachable } from "../../helpers/type.js";
import { QueryLink } from "./queryLink.js";
import { QueryLinkValue } from "./queryLinkValue.js";
//
// Model.
//
export var QueryFilterType;
(function (QueryFilterType) {
    QueryFilterType["LINK"] = "link";
    QueryFilterType["AND"] = "and";
    QueryFilterType["OR"] = "or";
})(QueryFilterType || (QueryFilterType = {}));
//
// I/O.
//
function queryLink(link) {
    return [QueryFilterType.LINK, link];
}
function queryAnd(...filters) {
    return [QueryFilterType.AND, filters];
}
function queryOr(...filters) {
    return [QueryFilterType.OR, filters];
}
function queryFilterToString(filter) {
    function toStringWithDepth(filter, depth) {
        function filtersToString(filters) {
            return filters.map(f => toStringWithDepth(f, depth + 1)).join(",");
        }
        function singleFilterInType(type, filters) {
            const onlyFilter = filters[0];
            if (!onlyFilter || filters.length !== 1) {
                return undefined;
            }
            if (onlyFilter[0] !== type) {
                return undefined;
            }
            const nestedFilters = onlyFilter[1];
            const onlyNestedFilter = nestedFilters[0];
            if (!onlyNestedFilter || nestedFilters.length !== 1) {
                return undefined;
            }
            return onlyNestedFilter;
        }
        function orAndToString(type, filters) {
            // Edge-cases.
            if (type === QueryFilterType.AND && depth === 0) {
                return filtersToString(filters);
            }
            const orSingleFilter = singleFilterInType(QueryFilterType.OR, filters);
            if (type === QueryFilterType.AND && orSingleFilter) {
                return toStringWithDepth(orSingleFilter, depth);
            }
            const andSingleFilter = singleFilterInType(QueryFilterType.AND, filters);
            if (type === QueryFilterType.OR && andSingleFilter) {
                return toStringWithDepth(andSingleFilter, depth);
            }
            // General case.
            return `(${filtersToString(filters)})`;
        }
        switch (filter[0]) {
            case QueryFilterType.LINK:
                return QueryLink.toString(filter[1]);
            case QueryFilterType.AND:
            case QueryFilterType.OR:
                return orAndToString(filter[0], filter[1]);
            default:
                unreachable(filter[0]);
        }
    }
    return toStringWithDepth(filter, 0);
}
function queryFilterToListString(queryFilter) {
    // Unwrap the top-level OR if present.
    if (queryFilter[0] === QueryFilterType.OR) {
        return queryFilter[1].map(queryFilterToString);
    }
    // Otherwise, simply wrap in a list.
    return [queryFilterToString(queryFilter)];
}
function queryFilterIsMatch(record, queryFilter) {
    switch (queryFilter[0]) {
        case QueryFilterType.LINK:
            return QueryLink.isInRecord(record, queryFilter[1]);
        case QueryFilterType.AND:
            return queryFilter[1].every(f => queryFilterIsMatch(record, f));
        case QueryFilterType.OR:
            return queryFilter[1].some(f => queryFilterIsMatch(record, f));
        default:
            unreachable(queryFilter[0]);
    }
}
function flattenQueryFilter(filter) {
    switch (filter[0]) {
        case QueryFilterType.LINK:
            return [[filter]];
        case QueryFilterType.AND:
            return filter[1].reduce((result, filter) => {
                const flattened = flattenQueryFilter(filter);
                if (result.length === 0) {
                    return flattened;
                }
                if (flattened.length === 0) {
                    return result;
                }
                return result.reduce((r1, f1) => {
                    return flattened.reduce((r2, f2) => {
                        r2.push([...f1, ...f2]);
                        return r2;
                    }, r1);
                }, []);
            }, []);
        case QueryFilterType.OR:
            return filter[1].flatMap(flattenQueryFilter);
        default:
            unreachable(filter[0]);
    }
}
function flatQueryFilterItemIsSuperset(item1, item2) {
    return (item2[0] === QueryFilterType.LINK &&
        QueryLink.isSuperset(item1[1], item2[1]));
}
function flatQueryFilterIsSuperset(filter1, filter2) {
    function subFilterIsSuperset(subFilter1, subFilter2) {
        return subFilter1.every(f1 => {
            return subFilter2.some(f2 => flatQueryFilterItemIsSuperset(f1, f2));
        });
    }
    if (filter1.length === 0) {
        return true;
    }
    if (filter2.length === 0) {
        return false;
    }
    return filter2.every(sf2 => {
        return filter1.some(sf1 => subFilterIsSuperset(sf1, sf2));
    });
}
function queryFilterIsSuperset(filter1, filter2) {
    const flatFilter1 = flattenQueryFilter(filter1);
    const flatFilter2 = flattenQueryFilter(filter2);
    return flatQueryFilterIsSuperset(flatFilter1, flatFilter2);
}
//
// Query builders.
//
function queryTag(path, tag) {
    return QueryFilter.link(QueryLink.pathLink(path, QueryLinkValue.tag(tag)));
}
function queryEntity(path, entity) {
    return QueryFilter.link(QueryLink.pathLink(path, QueryLinkValue.entity(entity)));
}
function queryRecord(path, recordLink) {
    return QueryFilter.link(QueryLink.pathLink(path, QueryLinkValue.record(recordLink)));
}
function queryVersion(path, versionLink) {
    return QueryFilter.link(QueryLink.pathLink(path, QueryLinkValue.version(versionLink)));
}
function queryId(id) {
    return QueryFilter.link(QueryLink.pathLink("id", QueryLinkValue.tag(id)));
}
function queryAuthor(entity) {
    return queryEntity("author", entity);
}
function queryType(type) {
    return QueryFilter.link(QueryLink.pathLink("type", QueryLinkValue.record({
        entity: type.type.entity,
        recordId: type.type.recordId,
    })));
}
function queryEmpty(path) {
    return QueryFilter.link(QueryLink.emptyPathLink(path));
}
export const QueryFilter = {
    link: queryLink,
    and: queryAnd,
    or: queryOr,
    tag: queryTag,
    entity: queryEntity,
    record: queryRecord,
    version: queryVersion,
    id: queryId,
    author: queryAuthor,
    type: queryType,
    empty: queryEmpty,
    toString: queryFilterToString,
    toListString: queryFilterToListString,
    isMatch: queryFilterIsMatch,
    isSuperset: queryFilterIsSuperset,
};
export const Q = QueryFilter;
