import * as IO from "../../helpers/io.js";
//
// Model.
//
function recordResponse(knownRecord, recordType) {
    return IO.object({
        record: recordType,
        linkedRecords: IO.readonlyArray(knownRecord),
    });
}
export const RecordResponse = {
    io: recordResponse,
};
