import * as IO from "../helpers/io.js";
import { Http } from "./http.js";
async function getApi(model, url, options) {
    const [headers, response] = await Http.get(url, options);
    return [headers, IO.decode(model, response)];
}
async function postApi(responseModel, bodyModel, body, url, options) {
    const [headers, response] = await Http.post(bodyModel.encode(body), url, options);
    return [headers, IO.decode(responseModel, response)];
}
async function putApi(responseModel, bodyModel, body, url, options) {
    const [headers, response] = await Http.put(bodyModel.encode(body), url, options);
    return [headers, IO.decode(responseModel, response)];
}
async function patchApi(responseModel, bodyModel, body, url, options) {
    const [headers, response] = await Http.patch(bodyModel.encode(body), url, options);
    return [headers, IO.decode(responseModel, response)];
}
async function deleteApi(responseModel, bodyModel, body, url, options) {
    const [headers, response] = await Http.deleteBody(bodyModel.encode(body), url, options);
    return [headers, IO.decode(responseModel, response)];
}
async function postBlobApi(responseModel, body, url, options) {
    const [headers, response] = await Http.post(body, url, options);
    return [headers, IO.decode(responseModel, response)];
}
function eventSourceApi(messageModel, onMessage, eventName, url, options) {
    const onRawMessage = (event) => {
        if (event.event !== eventName) {
            return;
        }
        const message = IO.decode(messageModel, JSON.parse(event.data));
        onMessage(message);
    };
    Http.eventSource(onRawMessage, url, options);
}
export const Api = {
    get: getApi,
    post: postApi,
    put: putApi,
    patch: patchApi,
    delete: deleteApi,
    postBlob: postBlobApi,
    eventSource: eventSourceApi,
};
