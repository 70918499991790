import * as IO from "../../helpers/io.js";
//
// Model.
//
const RBlobResponseRaw = IO.object({
    hash: IO.string,
    size: IO.Int,
    expiresAt: IO.isoDate,
});
export const RBlobResponse = IO.clean(RBlobResponseRaw);
