import { ed25519 } from "@noble/curves/ed25519";
export function buildKey() {
    const privateKey = ed25519.utils.randomPrivateKey();
    const publicKey = ed25519.getPublicKey(privateKey);
    return [publicKey, privateKey];
}
export function sign(privateKey, data) {
    return ed25519.sign(data, privateKey);
}
export function verify(publicKey, data, signature) {
    return ed25519.verify(signature, data, publicKey);
}
