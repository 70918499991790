function tryParseKey(key) {
    const separatorIndex = key.lastIndexOf("+");
    if (separatorIndex < 0) {
        return undefined;
    }
    const entity = key.slice(0, separatorIndex);
    const recordId = key.slice(separatorIndex + 1);
    return { entity, recordId };
}
function keyToComponents(key) {
    const separatorIndex = key.lastIndexOf("+");
    const entity = key.slice(0, separatorIndex);
    const recordId = key.slice(separatorIndex + 1);
    return { entity, recordId };
}
export const RecordKey = {
    tryParse: tryParseKey,
    toComponents: keyToComponents,
};
