import * as IO from "../../helpers/io.js";
import { SchemaIO } from "../../helpers/schemaIO.js";
//
// Model.
//
function tagLink(value) {
    return IO.literal(value);
}
const RAnyTagLink = SchemaIO.string({
    minLength: 1,
    maxLength: 100,
});
//
// Exports.
//
export const TagLink = {
    io: tagLink,
};
export const AnyTagLink = {
    io: () => RAnyTagLink,
};
