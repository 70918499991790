import { Constants } from "../../constants.js";
import * as IO from "../../helpers/io.js";
const { clientIdHeader, contentSha256Header, credentialsHeader, lastEventIdHeader, } = Constants;
//
// Model.
//
export var HttpSignatureHeader;
(function (HttpSignatureHeader) {
    HttpSignatureHeader["RANGE"] = "RANGE";
    HttpSignatureHeader["CLIENT_ID"] = "CLIENT_ID";
    HttpSignatureHeader["CONTENT_SHA_256"] = "CONTENT_SHA_256";
    HttpSignatureHeader["CREDENTIALS"] = "CREDENTIALS";
    HttpSignatureHeader["LAST_EVENT_ID"] = "LAST_EVENT_ID";
})(HttpSignatureHeader || (HttpSignatureHeader = {}));
export const RHttpSignatureHeader = IO.weakEnumerationWithValues(HttpSignatureHeader, {
    [HttpSignatureHeader.RANGE]: "range",
    [HttpSignatureHeader.CLIENT_ID]: clientIdHeader.toLowerCase(),
    [HttpSignatureHeader.CONTENT_SHA_256]: contentSha256Header.toLowerCase(),
    [HttpSignatureHeader.CREDENTIALS]: credentialsHeader.toLowerCase(),
    [HttpSignatureHeader.LAST_EVENT_ID]: lastEventIdHeader.toLowerCase(),
}, { isCaseSensitive: false });
