import * as IO from "../../helpers/io.js";
import { EntityLink } from "../links/entityLink.js";
//
// Runtime model.
//
const RPermissionLink = EntityLink.io();
export const RRecordPermissions = IO.partialObject({
    read: IO.union([IO.literal("public"), IO.readonlyArray(RPermissionLink)]),
    write: IO.readonlyArray(RPermissionLink),
    notify: IO.readonlyArray(RPermissionLink),
});
//
// I/O.
//
const publicPermissions = {
    read: "public",
};
const privatePermissions = {};
function buildSimplePermissions(authorEntity, otherEntities) {
    const authorEntityLink = EntityLink.new(authorEntity);
    const otherEntityLinks = otherEntities.map(EntityLink.new);
    return {
        read: [authorEntityLink, ...otherEntityLinks],
    };
}
function buildReadonlyPermissions(authorEntity, otherEntities) {
    const authorEntityLink = EntityLink.new(authorEntity);
    const otherEntityLinks = otherEntities.map(EntityLink.new);
    return {
        read: [authorEntityLink, ...otherEntityLinks],
        write: [],
    };
}
function toReadEntities({ read }) {
    if (!read || read === "public") {
        return [];
    }
    return read.map(l => l.entity);
}
export const RecordPermissions = {
    public: publicPermissions,
    private: privatePermissions,
    simple: buildSimplePermissions,
    readonly: buildReadonlyPermissions,
    toReadEntities,
};
