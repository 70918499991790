export function buildFetcher(promiseBuilder) {
    let res;
    const promise = promiseBuilder();
    promise.then(result => (res = { result }));
    return () => {
        if (!res) {
            throw promise;
        }
        return res.result;
    };
}
