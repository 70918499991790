import { createContext, useContext } from "react";
import { findEntityRecord, findRecordByKey, findRecordByQuery, findStandingDecision, recordByKey, recordByVersion, updateStandingDecision, } from "./storeHelpers.js";
//
// Helpers.
//
export function buildAccessors(entity, proxyEntity) {
    return {
        recordByKey: recordByKey(entity, proxyEntity),
        findRecordByKey: findRecordByKey(entity, proxyEntity),
        findRecordByQuery: findRecordByQuery(entity, proxyEntity),
        findEntityRecord: findEntityRecord(entity, proxyEntity),
        findStandingDecision: findStandingDecision(entity),
    };
}
export function buildHelpers(store, accessors, proxyEntity) {
    const { entity, client, versions, getStateSnapshot } = store;
    const { updateRecords, uploadBlob, buildBlobUrl, onDisconnectRequest } = store;
    const updateStanding = updateStandingDecision(entity);
    return {
        entity,
        proxyEntity,
        client,
        recordByVersion: recordByVersion(versions),
        recordByKey: accessors.recordByKey(getStateSnapshot),
        findRecordByKey: accessors.findRecordByKey(getStateSnapshot),
        findRecordByQuery: accessors.findRecordByQuery(getStateSnapshot),
        findEntityRecord: accessors.findEntityRecord(getStateSnapshot),
        findStandingDecision: accessors.findStandingDecision(getStateSnapshot),
        updateStandingDecision: updateStanding(getStateSnapshot, updateRecords),
        updateRecords,
        uploadBlob,
        buildBlobUrl,
        onDisconnectRequest,
    };
}
export function buildProxyStoreContext() {
    const ProxyStoreContext = createContext(undefined);
    function useProxyStoreContext() {
        const context = useContext(ProxyStoreContext);
        if (!context) {
            throw new Error("Store entity provider is required.");
        }
        return context;
    }
    return {
        ProxyStoreContext,
        useProxyStoreContext,
    };
}
