/* eslint-disable @typescript-eslint/no-unused-vars */
import * as IO from "../../helpers/io.js";
//
// Runtime model.
//
export class RRecordLinkClass extends IO.Type {
    constructor(_type) {
        const model = IO.dualObject({
            entity: IO.string,
            recordId: IO.string,
        }, {
            originalEntity: IO.string,
            versionCreatedAt: IO.isoDate,
        });
        super("RecordLink", model.is, model.validate, model.encode);
    }
}
function recordLink(type) {
    return new RRecordLinkClass(type?.RType);
}
function recordLinkOf(_entity, _recordId) {
    return new RRecordLinkClass({});
}
const RAnyRecordLink = recordLink();
//
// I/O.
//
function buildRecordLink(entity, recordId) {
    return { entity, recordId };
}
function linkToKey(link) {
    return `${link.entity}+${link.recordId}`;
}
function isLinkedRecord(record, recordLink) {
    return (record.author.entity === recordLink.entity &&
        record.id === recordLink.recordId);
}
function findLinkedRecord(records, link) {
    function isLink(record) {
        return isLinkedRecord(record, link);
    }
    return records.find(isLink);
}
function isSameRecordLink(link1, link2) {
    return link1.entity === link2.entity && link1.recordId === link2.recordId;
}
//
// Exports.
//
export const RecordLink = {
    io: recordLink,
    ioOf: recordLinkOf,
    new: buildRecordLink,
    toKey: linkToKey,
    isRecord: isLinkedRecord,
    findRecord: findLinkedRecord,
    isSame: isSameRecordLink,
};
export const AnyRecordLink = {
    io: () => RAnyRecordLink,
};
