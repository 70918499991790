import * as IO from "../../helpers/io.js";
//
// Model.
//
export function recordsResponse(knownRecord, recordType) {
    return IO.dualObject({
        pageSize: IO.number,
        records: IO.arrayIgnore(recordType),
        linkedRecords: IO.arrayIgnore(knownRecord),
    }, {
        nextPage: IO.string,
    });
}
