import { JSONPath } from "jsonpath-plus";
import snakeCase from "lodash/snakeCase.js";
export function normalizePath(path) {
    function mapPathElement(element) {
        const transformed = snakeCase(element);
        return transformed || element;
    }
    const pathArray = JSONPath.toPathArray(`$.${path}`).map(mapPathElement);
    return JSONPath.toPathString(pathArray);
}
