export function isReferenceEqual(a, b) {
    if (a === b) {
        return a !== 0 || b !== 0 || 1 / a === 1 / b;
    }
    else {
        return a !== a && b !== b;
    }
}
export function isShallowEqual(a, b) {
    if (isReferenceEqual(a, b)) {
        return true;
    }
    if (typeof a !== "object" ||
        a === null ||
        typeof b !== "object" ||
        b === null) {
        return false;
    }
    const keysA = Object.keys(a);
    const keysB = Object.keys(b);
    if (keysA.length !== keysB.length) {
        return false;
    }
    for (let i = 0; i < keysA.length; i++) {
        if (!Object.prototype.hasOwnProperty.call(b, keysA[i]) ||
            !isReferenceEqual(a[keysA[i]], b[keysA[i]])) {
            return false;
        }
    }
    return true;
}
